import React, { useState, useRef, useEffect } from 'react';

const InfoCircle = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const handleToggleTooltip = () => {setShowTooltip((prev) => !prev);};
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    useEffect(() => {
        if (showTooltip) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [showTooltip]);

    return (
        <div className="relative inline-block" ref={tooltipRef}>
            
            {/* Info Circle */}
            <div onClick={handleToggleTooltip} className="select-none rounded-full cursor-pointer flex items-center justify-center text-xs sm:text-lg size-5 sm:size-8 bg-white text-black dark:bg-stone-600 dark:text-white">
                i
            </div>

            {/* Tooltip */}
            {showTooltip && (
                <div className="absolute bottom-full mb-2 left-1/6 transform -translate-x-1/3 px-4 py-2 bg-stone-800 text-white text-xs sm:text-sm sm:w-34 rounded-xl shadow-lg">
                    {props.description} 
                </div>
            )}

        </div>
    );
}

const Bubble = ({children}) =>{
    return(
        <div className='w-full rounded-xl sm:rounded-2xl px-4 sm:px-6 py-3 sm:py-5 bg-stone-200 dark:bg-stone-500'>
                {children}
        </div>
    )
}

const InfoSection = ({title, children}) =>{
    return(
        <div className='w-full bg-stone-100 dark:bg-stone-600'>
            <div className='w-full px-6 sm:px-10 pb-4 sm:pb-8 mt-4'>
                <div className='flex justify-center py-2 sm:py-8 font-bold sm:text-4xl dark:text-white'>{title}</div>                
                {children}
            </div>
        </div>
    )
}

const ListSection = ({title, description, children}) =>{
    return(
        <div className='mt-4 pb-2 bg-stone-100 dark:bg-stone-600'>
            <div className='w-full px-6 sm:px-10 py-2 pt-4'>
                <div className='font-bold text-lg sm:text-5xl dark:text-white'>{title}</div>
                <div className='text-sm mb-2 sm:mb-4 sm:mt-2 text-stone-500 sm:text-2xl dark:text-stone-200'>{description}</div>
                {children}
            </div>
        </div>
    )
}

export const Common = () => {
  return (
    <div>Common</div>
  )
}

export {InfoSection, ListSection, Bubble, InfoCircle};