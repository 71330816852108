import React from 'react'

import { Villain } from "./subcomponents/Villain";
import { Statistics } from './subcomponents/Statistics';
import { Social } from './subcomponents/Social';
import { Dance } from "./subcomponents/Dance";

export const AboutPage = () => {
  const currentDate = new Date()
  return (
    <div>
        <Villain currentDate={currentDate}/>
        <Statistics currentDate={currentDate}/>
        <Social />
        <Dance />
    </div>
  )
}
