import React from 'react'

import { Hero } from "./subcomponents/Hero";
import { Projects } from "./subcomponents/Projects";
import { Skills } from "./subcomponents/Skills";

export const HomePage = () => {
  return (
    <div>
        <Hero />
        <Skills />
        <Projects />
    </div>
  )
}
