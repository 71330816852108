import React from 'react';
import photo from '../../assets/Z2.jpg'
import { Bubble, InfoCircle } from '../Common';

const TweetCard = (props) => {
    const nLikes = Math.floor(Math.random() * (10 - 1 + 1)) + 0;
    const nComments = Math.floor(Math.random() * (5 - 1 + 1)) + 0;

    return (
        <div className="w-full shadow bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-800 p-4 rounded-xl sm:rounded-2xl border max-w-xl">
            <div className="flex justify-between">
            <div className="flex items-center">
                <img className="h-11 sm:h-16 w-11 sm:w-16 rounded-full" src={photo}/>
                <div className="ml-2 text-xs sm:text-base leading-tight">
                    <span className="font-bold block text-black dark:text-white">Alessandro David</span>
                    <span className="font-normal block text-gray-500 dark:text-gray-400">@botalepower</span>
                </div>
            </div>
            <svg className="h-6 sm:h-8 w-auto inline-block fill-current text-blue-400 dark:text-white" viewBox="0 0 24 24"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
            </div>
            <p className="text-black dark:text-white block text-base sm:text-xl leading-snug mt-3">Waiting for my Raspberry Pi to implement this.</p>
            <p className="text-xs sm:text-base text-gray-500 dark:text-gray-400 py-1 my-0.5">{props.formattedDate}</p>
            <div className="border-gray-200 dark:border-gray-600 border border-b-0 my-1"></div>
            
            <div className="text-gray-500 dark:text-gray-400 flex justify-between mt-3">
                
                <div className='flex'> 
                    <div className="flex items-center mr-6">
                        <svg viewBox="0 0 24 24" className="fill-current size-5 sm:size-7 r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"><g><path d="M 12.001129,23.338583 H 11.98532 C 9.0708575,23.281033 0.6614175,15.207223 0.6614175,7.5602446 c 0,-3.6736195 2.849032,-6.89882715 6.096363,-6.89882715 2.583876,0 4.3215045,1.89435995 5.2422195,3.27316625 0.918461,-1.3764084 2.656087,-3.27316625 5.24109,-3.27316625 3.249589,0 6.097492,3.22520765 6.097492,6.90002715 0,7.6445794 -8.410566,15.7183894 -11.325041,15.7747414 h -0.01241 z M 6.7589095,2.4610595 c -2.346925,0 -4.403869,2.3835362 -4.403869,5.1015831 0,6.8820404 7.9366705,13.9031614 9.6472165,13.9774974 1.712804,-0.07434 9.647217,-7.094257 9.647217,-13.9774975 0,-2.7180469 -2.056944,-5.1015831 -4.40387,-5.1015831 -2.852415,0 -4.445619,3.5201521 -4.459158,3.5549221 -0.259516,0.673817 -1.304349,0.673817 -1.564993,0 -0.01581,-0.03597 -1.6078695,-3.5549221 -4.4614155,-3.5549221 z"></path></g></svg>
                        <span className="text-xs ml-2">{nLikes}</span>
                    </div>

                    <div className="flex items-center mr-6">
                        <svg viewBox="0 0 24 24" className="fill-current size-5 sm:size-7 r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"><g><path d="M 14.382486,0.67288895 9.6197002,0.66140665 h -0.00223 c -5.0222788,0 -8.95605298,3.93492275 -8.95605298,8.95835065 0,4.7053697 3.65820328,8.2740147 8.57140208,8.4623197 v 4.395358 c 0,0.124007 0.05052,0.328387 0.1377849,0.462728 0.1630476,0.258347 0.4409139,0.39843 0.7256728,0.39843 0.158453,0 0.318055,-0.04363 0.46158,-0.135489 0.303129,-0.1929 7.432378,-4.753597 9.286739,-6.322055 2.183877,-1.84862 3.490543,-4.558402 3.493987,-7.2475126 v -0.01953 C 23.331683,4.599774 19.40017,0.67288895 14.382485,0.67174075 Z M 18.730762,15.567496 c -1.302072,1.102282 -5.582606,3.909662 -7.775691,5.331146 v -3.65935 c 0,-0.475362 -0.38465,-0.861158 -0.861158,-0.861158 H 9.639219 c -4.2024551,0 -7.2544026,-2.842975 -7.2544026,-6.7583767 0,-4.0577816 3.1782508,-7.2360325 7.2337352,-7.2360325 l 4.7616364,0.011481 h 0.0023 c 4.055484,0 7.233734,3.1759544 7.23603,7.2291445 -0.0036,2.1930847 -1.081614,4.4137257 -2.886606,5.9431457 z"></path></g></svg>
                        <span className="text-xs ml-2">{nComments}</span>
                    </div>
                </div>
                <button>
                    <div className="flex justify-self-end items-center">
                        <svg viewBox="0 0 24 24" className="fill-current size-5 sm:size-7 r-1re7ezh r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"><g><path d="m 1.8956279,12.767116 c 0.513667,0 0.9372171,-0.373986 0.9372171,-0.892159 0.045058,-3.3748829 1.9915869,-6.4253439 4.969954,-8.0609679 4.487828,-2.464701 9.73264,-0.252327 11.74225,2.748569 l -2.834181,-0.590266 c -0.468608,-0.09463 -0.982275,0.189245 -1.076899,0.702913 -0.09464,0.468607 0.189246,0.982274 0.702914,1.076897 l 5.578245,1.171522 c 0.324421,0.0766 1.176025,-0.157704 1.076897,-1.03184 l -0.702913,-5.623303 c -0.04506,-0.468608 -0.423551,-0.797535 -0.892159,-0.797535 -0.698407,0 -0.982274,0.513667 -0.937215,1.03184 l 0.324419,2.744063 C 16.228742,-0.21424287 10.447734,0.54274013 6.9602053,2.1288001 3.3510181,4.0482931 1.0530341,7.7521021 1.0034691,11.829898 c 0,0.518172 0.378492,0.937218 0.8921588,0.937218 z M 22.099866,11.230619 c -0.513669,0 -0.937216,0.373987 -0.937216,0.892159 -0.04506,3.374883 -1.991587,6.425343 -4.969957,8.060969 -4.487826,2.4647 -9.7326377,0.252327 -11.742247,-2.748571 l 2.8341803,0.590268 C 7.7532351,18.120074 8.266902,17.836197 8.3615249,17.32253 8.4561479,16.853922 8.1722788,16.340255 7.6586122,16.245634 L 2.0848738,15.074112 c -0.3244215,-0.0766 -1.17602773,0.15771 -1.0768987,1.031839 l 0.7029129,5.623303 c 0.045059,0.468609 0.42355,0.797535 0.8921587,0.797535 0.6984069,0 0.9822757,-0.513668 0.9372171,-1.031841 L 3.2113367,18.750885 c 4.5554157,5.465597 10.3364233,4.70411 13.8239533,3.122554 3.609185,-1.923998 5.907172,-5.623299 5.952229,-9.705601 0.0044,-0.513668 -0.373986,-0.937219 -0.887653,-0.937219 z"></path></g></svg>
                    </div>
                </button>

            </div>
        </div>
    )
}

export const Tweet = (props) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: false, day: 'numeric', month: 'short', year: 'numeric' }
    const temp = props.currentDate.toLocaleString('en-US', options).split(',')
    const formattedDate = temp[2] + ' · ' +temp[0]+ ',' +temp[1]

    return (
    <Bubble>
        <div className='flex justify-between mb-2 text-sm text-stone-700 sm:text-2xl dark:text-stone-100'>Generate a Tweet: <InfoCircle description="Work in progress."/></div>
        <TweetCard formattedDate={formattedDate}/>
    </Bubble>
  )
}
