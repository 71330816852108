import React from 'react';
import { Footer } from "./components/subcomponents/Footer";
import { Navbar } from "./components/subcomponents/Navbar";

import { HomePage } from "./components/HomePage";
import { AboutPage } from "./components/AboutPage";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="dark:bg-stone-700">
      
      <Router>
        <Navbar />
        
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </Router>
      
      <Footer />
    </div>
  );
}

export default App;
