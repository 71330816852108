import React from 'react'
import { useState, useEffect } from 'react'

export const ThemeToggle = () => {
    const [darkMode, setDarkMode] = useState(false)
    
    // Al caricamento iniziale, controlla se la modalità dark è già attiva 
    // (ad esempio memorizzata nel localStorage o nelle preferenze del sistema)
    useEffect(() => {
        const root = window.document.documentElement;
        const initialTheme = localStorage.getItem('theme');
        
        if (initialTheme === 'dark' || (!initialTheme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            root.classList.add('dark');
            setDarkMode(true);
        }
    }, []);

    // Funzione per gestire il cambio di tema
    const toggleTheme = () => {
        const root = window.document.documentElement;
    
        if (darkMode) root.classList.remove('dark');
        else root.classList.add('dark');
    
        localStorage.setItem('theme', darkMode ? 'light' : 'dark');
        setDarkMode(!darkMode);
    };

    var darkDiv = <svg viewBox="0 0 24 24" className="fill-current size-3 sm:size-6"><g><path d="m 7.0406135,0.34024732 c 0,0 -2.4739317,19.09781068 16.4089625,16.74987268 C 23.295504,21.989594 8.3400034,28.363412 2.23944,16.059486 0.71424615,12.983397 -0.14207624,5.3599537 7.0406135,0.34024732"></path></g></svg>
    var lightDiv = <svg viewBox="0 0 24 24" className="fill-current size-3 sm:size-6"><g><path d="M 12.003373,7.7053479 A 4.2950978,4.2950978 0 0 1 16.295098,11.99902 4.2950978,4.2950978 0 0 1 12.006222,16.295538 4.2950978,4.2950978 0 0 1 7.7049163,12.011464 4.2950978,4.2950978 0 0 1 11.984183,7.7053757 L 12,12.000444 Z M 10.850705,17.48278 h 2.298591 v 5.651006 h -2.298591 z m 0,-16.61656565 h 2.298591 V 6.5172196 H 10.850705 Z M 17.48278,10.850705 v 2.298591 h 5.651006 v -2.298591 z m -16.61656575,0 v 2.298591 H 6.5172198 V 10.850705 Z M 15.064237,7.3104147 16.689586,8.9357637 20.685451,4.939899 19.060101,3.3145497 Z M 3.3145504,19.060101 4.9398997,20.68545 8.9357637,16.689586 7.3104144,15.064237 Z m 13.3750356,-3.995864 -1.625349,1.625349 3.995865,3.995865 1.625349,-1.62535 z M 4.9399001,3.3145503 3.3145509,4.9398996 7.3104154,8.9357637 8.9357647,7.3104147 Z"></path></g></svg>
    return (
        <button onClick={toggleTheme} className="flex justify-center items-center dark:text-stone-800 bg-stone-800 dark:bg-stone-200 p-1 sm:p-2 aspect-square rounded-full">
            {darkMode ? lightDiv : darkDiv }
        </button>
    )
}
