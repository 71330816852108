import React from 'react'
import { Music } from './Music';
import { Quote } from './Quote';
import { Tweet } from './Tweet';
import { Workout } from './Workout';
import { InfoSection} from '../Common'

export const Statistics = (props) => {
  return (
    <InfoSection title='Statistics'>
      <div className='grid gap-4 sm:gap-10 sm:grid-cols-2'> 
        <Music currentDate={props.currentDate}/>
        <Quote />
        <Workout />
        <Tweet currentDate={props.currentDate}/>
      </div> 
    </InfoSection>
  )
}